<template>
  <div>
    <UserFeedback :target="t('componentNames.adminUserExam')" class="end mr-4 mt-1"></UserFeedback>
    <b-field grouped group-multiline>
      <b-field>
        <b-button
          icon-left="refresh"
          @click="getUserExams"
          :loading="isLoading"
          size="is-small"
        >{{t('common.refresh')}}</b-button>
      </b-field>
    </b-field>
    <b-table :data="exams" paginated per-page="12">
      <b-table-column
        field="studentName"
        searchable
        sortable
        :label="t('common.student')"
        v-slot="props"
      >{{ props.row.studentName }}</b-table-column>
      <b-table-column
        field="classroomName"
        :label="t('classroom.header')"
        searchable
        sortable
        v-slot="props"
      >{{ props.row.classroomName }}</b-table-column>
      <b-table-column
        field="teacherName"
        :label="t('classroom.teacher')"
        searchable
        sortable
        v-slot="props"
      >{{ props.row.teacherName }}</b-table-column>
      <b-table-column
        field="time"
        :label="t('exam.userTime')"
        sortable
        v-slot="props"
      >{{ mformattedTimeLeft(props.row.time) }}</b-table-column>
      <b-table-column field="tags" :label="t('forms.tags')" v-slot="props">
        <span v-for="tag in props.row.tags" :key="tag" class="tag mr-2">{{ tag }}</span>
      </b-table-column>
      <b-table-column field="grade" :label="t('exam.grade')" v-slot="props">{{ props.row.grade }}</b-table-column>
      <b-table-column field="state" :label="t('common.state')" v-slot="props">
        <b-tooltip v-if="props.row.isFinished" :label="t('common.sended')" position="is-right">
          <b-icon icon="check"></b-icon>
        </b-tooltip>
        <b-tooltip v-else :label="t('common.notSended')" position="is-right">
          <b-icon icon="clock"></b-icon>
        </b-tooltip>
      </b-table-column>
      <b-table-column field="option" v-slot="props" :label="t('common.options')">
        <b-tooltip :label="t('exam.remove')">
          <b-button
            type="is-danger"
            :loading="isLoading"
            @click="confirmDelete(props.row.id)"
            icon-left="delete"
            size="is-small"
          ></b-button>
        </b-tooltip>
        <b-tooltip :label="t('grade.undo')" v-if="props.row.isGraded">
          <b-button
            :loading="isLoading"
            type="is-warning"
            @click="confirmRemoveGrade(props.row.id)"
            icon-left="star-minus"
            size="is-small"
          ></b-button>
        </b-tooltip>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
//import DetailUser from "@/components/username/DetailUser";
import { Action } from "@/store/config_actions";
import FormattedHelpers from "@/mixins/formatted";
import UserFeedback from '@/components/feedbacks/UserFeedback'

export default {
  name: "MentorDetailTables",
  mixins: [CommonHelpers, FormattedHelpers],
  components: {
    UserFeedback
  },

  props: {},

  data: function () {
    return {
      exams: [],
      classrooms: [],
      isLoading: false,
    };
  },

  mounted() {
    this.getUserExams();
  },

  watch: {},

  computed: {},

  methods: {
    confirmDelete(id) {
      this.$buefy.dialog.confirm({
        title: this.t('exam.removeUserExam'),
        message: this.t('exam.removeUserExamConfirm'),
        onConfirm: () => this.deleteUserExam(id),
        type: "is-danger",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
    },

    confirmRemoveGrade(id) {
      this.$buefy.dialog.confirm({
        title: this.t('grade.undo'),
        message: this.t('grade.undoConfirm'),
        onConfirm: () => this.removeGrade(id),
        type: "is-danger",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
    },

    removeGrade(id) {
      this.getUserExamAndUpdate(id);
    },

    deleteUserExam(id) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.USEREXAM_REMOVE, id)
        .then(() => {
          this.mSuccessSnack(this.t('exam.userExamRemoved'));
          this.getUserExams();
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getClassroomName(id) {
      let classroom = this.classrooms.find((c) => c.id == id);
      if (classroom) {
        return classroom.name;
      } else {
        return " ";
      }
    },

    getClassroomTeacher(id) {
      let classroom = this.classrooms.find((c) => c.id == id);
      if (classroom) {
        return classroom.teacher;
      } else {
        return null;
      }
    },

    getClassroom(id) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_GET_ONE_BY_ID, id)
        .then((payload) => {
          if (!this.classrooms.includes(payload)) {
            this.classrooms.push(payload);
          }
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getClassroomToExams() {
      this.exams.forEach((ex) => {
        this.getClassroom(ex.classId);
      });
    },

    /** Get all userExams, and get all classrooms */
    getUserExams() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.USEREXAM_GET_ALL)
        .then((payload) => {
          this.exams = payload;
          //this.getClassroomToExams();
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    /** Update UserExam*/
    updateUserExam(currentUserExam) {
      this.$store
        .dispatch(Action.USEREXAM_UPDATE, currentUserExam)
        .then(() => {
          this.mSuccessSnack(this.t('grade.undoSuccess'));
          this.getUserExams();
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
        });
    },

    /** Get UserExam and set grade as 0 and isGraded as false */
    getUserExamAndUpdate(id) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.USEREXAM_GET_EXAM_BY_ID, id)
        .then((payload) => {
          let userExam = payload;
          userExam.grade = 0;
          userExam.isGraded = false;
          this.updateUserExam(userExam);
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.exam-modal {
  background-color: white;
}
div.end {
  float: right;
}
</style>
