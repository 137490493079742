<template>
  <div>
    <UserFeedback :target="t('componentNames.afmConfig')" class="end mr-4 mt-1"></UserFeedback>
    <b-field class="buttons">
      <b-button
        icon-left="refresh"
        :loading="isLoading"
        @click="getAfmConfig"
        size="is-small"
      >
        {{t('common.refresh')}}
      </b-button>
      <b-button
        icon-left="new-box"
        :loading="isLoading"
        @click="createAfmConfig"
        size="is-small"
        type="is-danger"
      >
        {{t('config.createNew')}}
      </b-button>
      <b-button
        v-if="afmConfig"
        icon-left="check"
        :loading="isLoading"
        @click="saveAfmConfig"
        size="is-small"
        type="is-success"
        >{{t('config.save')}}</b-button
      >
    </b-field>
    <template v-if="afmConfig">
      <b-field :label="t('config.universities')">
        <b-taginput
          v-model="afmConfig.universities"
          ellipsis
          icon="plus"
          :placeholder="t('common.add')"
          maxlength="300"
          type="is-dark"
          :aria-close-label="t('common.remove')"
        ></b-taginput>
      </b-field>

      <b-field :label="t('config.subjects')">
        <b-taginput
          v-model="afmConfig.subjects"
          ellipsis
          icon="plus"
          :placeholder="t('common.add')"
          maxlength="300"
          type="is-info"
          :aria-close-label="t('common.remove')"
        ></b-taginput>
      </b-field>

      <b-field :label="t('config.specializations')">
        <b-taginput
          v-model="afmConfig.specializations"
          ellipsis
          icon="plus"
          :placeholder="t('common.add')"
          maxlength="300"
          type="is-primary"
          :aria-close-label="t('common.remove')"
        ></b-taginput>
      </b-field>
      
      <b-field :label="t('config.questionCategories')">
        <b-taginput
          v-model="afmConfig.questionCategories"
          ellipsis
          icon="plus"
          :placeholder="t('common.add')"
          maxlength="300"
          type="is-primary"
          :aria-close-label="t('common.remove')"
        ></b-taginput>
      </b-field>

      <b-field :label="t('config.deepZoomCategories')">
        <b-taginput
          v-model="afmConfig.deepZoomCategories"
          ellipsis
          icon="plus"
          :placeholder="t('common.add')"
          maxlength="300"
          type="is-primary"
          :aria-close-label="t('common.remove')"
        ></b-taginput>
      </b-field>

      <b-field :label="t('config.deepZoomSources')">
        <b-taginput
          v-model="afmConfig.deepZoomSources"
          ellipsis
          icon="plus"
          :placeholder="t('common.add')"
          maxlength="300"
          type="is-secondary"
          :aria-close-label="t('common.remove')"
        ></b-taginput>
      </b-field>


      <hr />
      <b-button
        v-if="afmConfig"
        icon-left="check"
        :loading="isLoading"
        @click="saveAfmConfig"
        type="is-success"
      >
        {{t('config.save')}}
      </b-button>
    </template>
  </div>
</template>

<script>
import Commons from "@/mixins/commons";
import { Action } from "../../store/config_actions";
import UserFeedback from '@/components/feedbacks/UserFeedback'

export default {
  name: "AuthUsers",
  mixins: [Commons],
  components: {UserFeedback},

  data: function () {
    return {
      isLoading: false,
      refreshTrigger: 0,
      afmConfig: null,
      currentSubject: null,
      currentUniversity: null,
      currentSpec: null,
      newSubject: null,
      newUniversity: null,
      newSpec: null,
      newAfmConfig: {
        universities: [],
        specializations: [],
        subjects: [],
        questionCategories: [],
        deepZoomCategories: [],
        mediaCategories: []
      },
    };
  },

  mounted() {
    this.getAfmConfig();
  },

  methods: {
    createAfmConfig() {
      this.$buefy.dialog.confirm({
        title: this.t('config.createNew'),
        message:this.t('config.createConfirm'),
        onConfirm: () => {
          this.afmConfig = JSON.parse(JSON.stringify(this.newAfmConfig));
        },
        type: "is-info",
        cancelText: this.t('common.no'),
        confirmText: this.t('config.yes'),
        hasIcon: true,
      });
    },

    /**
     * Get AFM config
     */
    getAfmConfig() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.AFM_CONFIG_GET)
        .then((payload) => {
          this.afmConfig = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    /**
     *
     */
    saveAfmConfig() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.AFM_CONFIG_CREATE, this.afmConfig)
        .then((payload) => {
          this.afmConfig = payload;
          this.mSuccessSnack(this.t('config.saved'));
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.end{
  float: right;
}
</style>
