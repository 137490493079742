<template>
  <div class="home">
    <GodMode />
  </div>
</template>

<script>
// @ is an alias to /src
import GodMode from '@/components/godMode/GodMode.vue'

export default {
  name: 'UsersView',
  components: {
    GodMode
  }
}
</script>