<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">
    <b-tabs :animated="false">
      <b-tab-item :label="t('componentNames.userExams')" icon="file-multiple">
        <UserExamAdminList :refreshTrigger="refreshTrigger"></UserExamAdminList>
      </b-tab-item>
      <b-tab-item :label="t('componentNames.afmConfig')" icon="view-list">
        <AfmConfig></AfmConfig>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import Commons from "@/mixins/commons";
import UserExamAdminList from "@/components/userexam/userexamAdmin/UserExamAdminList"
import AfmConfig from "./AfmConfig"

export default {
  name: "GodMode",
  mixins: [Commons],
  components: {
    UserExamAdminList,
    AfmConfig
  },

  data: function () {
    return {
      refreshTrigger: 0
    };
  },

  mounted() {
  },

  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
